<template>
  <div class="area-product">
    <!--  头部轮播  -->
    <div class="content">
      <div class="box">
        <div class="area">
          <div class="title">
            <h3>区域智能物联管控平台</h3>
            <!-- <p class="title_en">District-school Course Management Platform</p> -->
          </div>
          <div class="area_content">
            <p>
              区域智能物联管控平台采用“区校双平台”设计，区域总平台和学校平台并行运行，互联互通，实时呈现整个区域内所有学校的设备数据、水电能耗数据、时长数据、能耗占比数据等。解决主管部门无法实时精准掌握区域每一所学校教育装备运行状态信息，难以进行有效监管，教装资金投入效果不清楚；无法直观查看、掌握各区域学校能耗情况，难以及时发现能耗异常设施设备；缺乏学校能耗对比数据，难以制定科学合理节能措施，能耗双控、双碳、绿色学校等政策执行缺乏考核评定依据等难题。
            </p>
          </div>
        </div>
        <div class="platform">
          <img src="@/assets/images/areaProduct/SmartUnion/pic.png" alt="" />
          <p>
            通过区域智能物联管控平台，将绿色、节能理念纳入学校日常管理工作中，培养了师生的节能意识，提升了环境素养，有效推动生态素质教育。为节约型校园深入开展与实施提供良好的技术支持，降低学校的管网漏损率，减少故障率，保障校园供水、供电的安全性和可持续性，降低办学成本，提高办学效益。通过量化区域内各学校用能数据，明晰能耗管理主体责任，对区域学校能耗进行整体把控，复盘及规划教育领域能源管理制度制定，极大提升能源管理水平，多层次、高质量提升区域整体办学水平和社会形象，增强区域学校综合竞争力和影响力，促进区域教育可持续发展。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { swiper, swiperSlide, directive } from 'vue-awesome-swiper'
// import 'swiper/swiper-bundle.css'
import platformBottomPc from '@/assets/images/areaProduct/platform_bottom_pc.png'
import platformBottomMobile from '@/assets/images/areaProduct/platform_bottom_mobile.png'

export default {
  name: 'School',
  components: {
    // swiper,
    // swiperSlide,
  },
  directives: {
    // swiper: directive,
  },
  data() {
    return {
      platformBottomPc,
      platformBottomMobile,
      navList: [
        { label: '区域课程管理平台', url: '/products-plans/area' },
        { label: '区域校园安全风险防控平台', url: '/products-plans/safety' },
        {
          label: '区域课后服务云平台',
          url: '/products-plans/afterschool',
        },
      ],
      activeNav: 0,
      swiperList: [
        {
          url: '图片路径',
          title: '图片描述',
        },
      ], // 轮播图片
      swiperOption: {
        // swiper操作项
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          // dynamicBullets: true,
        },
      },
    }
  },
  mounted() {
    // 设置header背景透明
    this.$store.commit('setPcHeaderIsTransparent', true)
  },
  methods: {
    goPage(index, url) {
      this.activeNav = index
      this.$router.push(url)
    },
  },
}
</script>

<style scoped lang="scss">
%area {
  .content {
    background: #f7f7f8;
    padding-bottom: 50px;
    // padding-top: 50px;
    .box {
      max-width: 1320px;
      width: 90%;
      margin: 0 auto 60px;

      .title {
        text-align: center;
        color: #333;
        h3 {
          line-height: 36px;
          font-size: 36px;
          font-weight: bold;
          margin: 0;
        }
        .title_en {
          font-size: 24px;
        }
        .title_en::after {
          content: '';
          display: block;
          width: 66px;
          height: 3px;
          margin: 20px auto;
          background-color: $--color-primary;
        }
      }
      .area {
        padding: 40px 0px 0;
        &-img {
          line-height: 36px;
          font-size: 36px;
        }
        &_content {
          & > div:nth-child(1) {
            width: calc(100% - 20px);
            max-width: 800px;
            text-align: center;
            padding: 36px 0;
            border: 1px solid #979797;
            font-size: 28px;
            margin: 60px auto;
          }
          & > p {
            line-height: 32px;
            font-size: 18px;
            text-align: justify;
            color: #666;
            margin: 40px 0 50px;

            span {
              color: $--color-primary;
            }
          }
        }
      }

      .platform {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: $--color-white;
        padding: 40px 20px;
        img {
          width: 30%;
        }
        & > p {
          max-width: 744px;
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          // text-align: justify;
          span {
            color: $--color-primary;
          }
        }
      }
    }
    .campus_box {
      background: $--color-white;
      .campus {
        text-align: center;
        padding-top: 120px;
        .title {
          text-align: center;
          color: #333;
          margin-bottom: 80px;
          h3 {
            line-height: 60px;
            font-size: 40px;
            font-weight: bold;
            margin: 0;
          }
        }
        img {
          padding-bottom: 40px;
          display: block;
          margin: 50px auto 0;
          width: 55%;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
  }
  @media (min-width: 700px) and (max-width: 1200px) {
  }
}
.view-pc {
  @extend %area;
  @media screen and (max-width: 1200px) {
    .area {
      // padding: 0 !important;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      // &_content {
      //   div:nth-child(1) span {
      //     padding: 0 20px;
      //   }
      //   p {
      //     margin-top: 30px;
      //     font-size: 18px;
      //     margin-bottom: 0;
      //   }
      // }
    }
    .platform {
      width: calc(90% - 40px);
      margin-left: auto;
      margin-right: auto;
      img {
        margin-right: 25px;
      }
    }
  }
  @media (min-width: 570px) and(max-width:1440px) {
    .box {
      // max-width: 1100px !important;
      .area {
        // padding: 0 !important;
        // &_content {
        //   div:nth-child(1) span {
        //     padding: 0 20px;
        //   }
        //   p {
        //     margin-top: 30px;
        //     font-size: 18px;
        //     margin-bottom: 0;
        //   }
        // }
      }
      .platform {
        img {
          margin-right: 25px;
        }
      }
    }
  }
}
// mobile会将px转成vw
.view-mobile {
  @extend %area;
  @media screen and (max-width: 700px) {
    .area-product {
      margin-top: -15px !important;
      .content {
        padding-top: 0px;
        .title_en {
          font-size: 14px !important;
          margin-top: 10px;
        }
        h3 {
          line-height: 30px !important;
        }
        .box {
          width: 90%;
          margin: 0 auto;
          .area {
            padding: 0 0 30px;
            &_content {
              div:nth-child(1) span {
                padding: 0 20px;
              }
              p {
                margin-top: 20px;
                font-size: 14px;
                line-height: 28px;
                margin-bottom: 0;
              }
            }
          }
          .platform {
            width: 90%;
            display: block;
            padding: 20px;
            img {
              width: 80%;
              display: block;
              margin: 20px auto;
            }
            p {
              font-size: 14px;
              line-height: 28px;
              color: #666;
              text-align: justify;
            }
          }
        }
        .campus_box {
          .pt0 {
            padding-top: 0 !important;
          }
          .campus {
            padding: 30px 15px 0;
            img {
              width: 100%;
              margin: 0 0 20px;
            }
          }
        }
      }
    }
  }
}
</style>
