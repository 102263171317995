<template>
  <div class="area-product">
    <!--  头部轮播  -->
    <div class="content">
      <div class="box">
        <div class="area">
          <div class="title">
            <h3>区域课程管理平台</h3>
            <!-- <p class="title_en">District-school Course Management Platform</p> -->
          </div>
          <div class="area_content">
            <p>
              <span>区域课程管理平台</span>
              为区域教育管理者提供基干大数据分析的科学决策，对区域内教师资源、教室资源、班额、师生比、课程、学生选科、走班思路与策略等方面提供有效的监控与分析；通过大数据基础平台的建设，深度挖掘常规业务数据的业务价值，提供更加科学有效的分析服务。
            </p>
          </div>
        </div>
        <div class="platform">
          <img src="@/assets/images/areaProduct/platform.png" alt="" />
          <p>
            为学校提供特色的
            <span
              >行政班排课、走班排课、智能分班、校本选课、新高考选课、教师调代课和各维度课表查询</span
            >的丰富功能。帮助学校一站式管理，支持灵活地定制符合学校自己实际情况的教务功能。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { swiper, swiperSlide, directive } from 'vue-awesome-swiper'
// import 'swiper/swiper-bundle.css'
import platformBottomPc from '@/assets/images/areaProduct/platform_bottom_pc.png'
import platformBottomMobile from '@/assets/images/areaProduct/platform_bottom_mobile.png'

export default {
  name: 'School',
  components: {
    // swiper,
    // swiperSlide,
  },
  directives: {
    // swiper: directive,
  },
  data() {
    return {
      platformBottomPc,
      platformBottomMobile,
      navList: [
        { label: '区域课程管理平台', url: '/products-plans/area' },
        { label: '区域校园安全风险防控平台', url: '/products-plans/safety' },
        {
          label: '区域课后服务云平台',
          url: '/products-plans/afterschool',
        },
      ],
      activeNav: 0,
      swiperList: [
        {
          url: '图片路径',
          title: '图片描述',
        },
      ], // 轮播图片
      swiperOption: {
        // swiper操作项
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          // dynamicBullets: true,
        },
      },
    }
  },
  mounted() {
    // 设置header背景透明
    this.$store.commit('setPcHeaderIsTransparent', true)
  },
  methods: {
    goPage(index, url) {
      this.activeNav = index
      this.$router.push(url)
    },
  },
}
</script>

<style scoped lang="scss">
%area {
  .content {
    background: #f7f7f8;
    padding-bottom: 40px;
    // padding-top: 40px;
    .box {
      width: 90%;
      max-width: 1320px;
      margin: 0 auto 60px;

      .title {
        text-align: center;
        color: #333;
        h3 {
          line-height: 36px;
          font-size: 36px;
          font-weight: bold;
          margin: 0;
        }
        .title_en {
          font-size: 24px;
        }
        .title_en::after {
          content: '';
          display: block;
          width: 66px;
          height: 3px;
          margin: 20px auto;
          background-color: $--color-primary;
        }
      }
      .area {
        padding: 40px 0px 0;
        &_content {
          & > div:nth-child(1) {
            width: calc(100% - 20px);
            max-width: 800px;
            text-align: center;
            padding: 36px 0;
            border: 1px solid #979797;
            font-size: 28px;
            margin: 60px auto;
          }
          & > p {
            line-height: 32px;
            font-size: 18px;
            text-align: justify;
            color: #666;
            margin: 40px 0 50px;

            span {
              color: $--color-primary;
            }
          }
        }
      }

      .platform {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: $--color-white;
        padding: 40px 20px;
        img {
          width: 30%;
        }
        & > p {
          max-width: 744px;
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          text-align: justify;
          span {
            color: $--color-primary;
          }
        }
      }
    }
  }
  @media screen and (max-width: 700px) {
  }
  @media (min-width: 700px) and (max-width: 1200px) {
  }
}
.view-pc {
  @extend %area;
  @media screen and (max-width: 1200px) {
    .area {
      // padding: 0 !important;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      // &_content {
      //   div:nth-child(1) span {
      //     padding: 0 20px;
      //   }
      //   p {
      //     margin-top: 30px;
      //     font-size: 18px;
      //     margin-bottom: 0;
      //   }
      // }
    }
    .platform {
      width: calc(90% - 40px);
      margin-left: auto;
      margin-right: auto;
      img {
        margin-right: 25px;
      }
    }
  }
  @media (min-width: 570px) and(max-width:1440px) {
    .box {
      // max-width: 1100px !important;
      .area {
        // padding: 0 !important;
        // &_content {
        //   div:nth-child(1) span {
        //     padding: 0 20px;
        //   }
        //   p {
        //     margin-top: 30px;
        //     font-size: 18px;
        //     margin-bottom: 0;
        //   }
        // }
      }
      .platform {
        img {
          margin-right: 25px;
        }
      }
    }
  }
}
// mobile会将px转成vw
.view-mobile {
  @extend %area;
  @media screen and (max-width: 700px) {
    .area-product {
      margin-top: -15px !important;
      .content {
        padding-top: 0px;
        .title_en {
          font-size: 14px !important;
          margin-top: 10px;
        }
        h3 {
          line-height: 30px !important;
        }
        .box {
          width: 90%;
          margin: 0 auto;
          .area {
            padding: 0 0 30px;
            &_content {
              div:nth-child(1) span {
                padding: 0 20px;
              }
              p {
                margin-top: 20px;
                font-size: 14px;
                line-height: 28px;
                margin-bottom: 0;
              }
            }
          }
          .platform {
            width: 90%;
            display: block;
            padding: 20px;
            img {
              width: 100%;
              display: block;
              margin: 0;
            }
            p {
              font-size: 14px;
              line-height: 28px;
              color: #666;
              text-align: justify;
            }
          }
        }
      }
    }
  }
}
</style>
