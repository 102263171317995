<template>
  <div class="area-product">
    <!--  头部轮播  -->
    <div class="content">
      <div class="box">
        <div class="area">
          <div class="title">
            <h3>区域校园安全风险防控平台</h3>
            <!-- <p class="title_en">District-school Safety Management Platform</p> -->
          </div>
          <div class="area_content">
            <!-- <div><span>该平台已累计服务**省市，**区域</span></div> -->
            <p>
              为区域教育局建设多级安全管理风险防控平台:可覆盖省级、地市级、区县级;以教育主管部门为中心，下联各级各类学校、家庭、校园安全相关的联防单位和社会组织，构建互联互通的校园安全风险防控统一平台。能够服务于全市中、小学校、学生及家长，逐级分级、分权限进行管理和应用，综合利用
              <span>【大数据+云计算+智能物联】</span>
              等先进科技手段，建立平安校园工作互通体系，保障安全防范工作逐级、科学、规范地开展。
            </p>

            <div class="platform">
              <img src="@/assets/images/areaProduct/platform_n.png" alt="" />
              <p>
                尤其是来自学校的安全工作数据通过智能终端及闭环工作流机制，及时上报、汇总到教育局端，充分实现校园安全管理工作的动态监管、过程和结果的量化评价、智能统计分析及预测。区校一体化平台可扩展为:
                <span>教育集团模式，跨区域、多学校的一体化安全管理平台。</span>
              </p>
            </div>
          </div>
        </div>
        <!--  纵向统一领导  -->
        <div class="campus">
          <img
            :src="
              $store.state.deviceWidth > 769
                ? platformBottomPc
                : platformBottomMobile
            "
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { swiper, swiperSlide, directive } from 'vue-awesome-swiper'
// import 'swiper/swiper-bundle.css'
import platformBottomPc from '@/assets/images/areaProduct/platform_bottom_pc.png'
import platformBottomMobile from '@/assets/images/areaProduct/platform_bottom_mobile1.png'

export default {
  name: 'School',
  components: {
    // swiper,
    // swiperSlide,
  },
  directives: {
    // swiper: directive,
  },
  data() {
    return {
      platformBottomPc,
      platformBottomMobile,
      navList: [
        { label: '区域课程管理平台', url: '/products-plans/area' },
        { label: '区域校园安全风险防控平台', url: '/products-plans/safety' },
        {
          label: '区域课后服务云平台',
          url: '/products-plans/afterschool',
        },
      ],
      activeNav: 1,
      swiperList: [
        {
          url: '图片路径',
          title: '图片描述',
        },
      ], // 轮播图片
      swiperOption: {
        // swiper操作项
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          // dynamicBullets: true,
        },
      },
    }
  },
  mounted() {
    // 设置header背景透明
    this.$store.commit('setPcHeaderIsTransparent', true)
  },
  methods: {
    goPage(index, url) {
      this.activeNav = index
      this.$router.push(url)
    },
  },
}
</script>

<style scoped lang="scss">
%area {
  .content {
    background: #f7f7f8;
    padding-bottom: 60px;
    // padding-top: 40px;
    .box {
      width: 90%;
      max-width: 1320px;
      margin: 0 auto;
      // 通用头部样式
      .title {
        text-align: center;
        color: #333;
        h3 {
          line-height: 36px;
          font-size: 36px;
          font-weight: bold;
          margin: 0;
        }
        .title_en {
          font-size: 24px;
        }
        .title_en::after {
          content: '';
          display: block;
          width: 66px;
          height: 3px;
          margin: 20px auto;
          background-color: $--color-primary;
        }
      }
      // 新高考
      .area {
        padding: 40px 0px 0;
        &_content {
          & > div:nth-child(1) {
            width: calc(100% - 20px);
            max-width: 800px;
            text-align: center;
            padding: 36px 0;
            border: 1px solid #979797;
            font-size: 28px;
            margin: 60px auto;
          }
          & > p {
            margin: 40px 0 50px;
            line-height: 32px;
            font-size: 18px;
            text-align: justify;
            color: #666;
            span {
              color: $--color-primary;
            }
          }
          .platform {
            display: flex;
            justify-content: space-around;
            align-items: center;
            background: $--color-white;
            padding: 40px 20px;
            margin-bottom: 30px;
            & > p {
              max-width: 744px;
              line-height: 32px;
              font-size: 18px;
              text-align: justify;
              color: #666;
              text-align: justify;
              span {
                color: $--color-primary;
              }
            }
            img {
              width: 30%;
            }
          }
        }
      }
      // 智慧校园
      .campus {
        margin: 0px auto;
        text-align: center;
        img {
          width: 100%;
        }
      }
    }
  }
}
.view-pc {
  @extend %area;
  .content {
    padding-bottom: 80px;
  }
  @media screen and (max-width: 1200px) {
    .area {
      // padding: 0 !important;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      // &_content {
      //   div:nth-child(1) span {
      //     padding: 0 20px;
      //   }
      //   p {
      //     margin-top: 30px;
      //     font-size: 18px;
      //     margin-bottom: 0;
      //   }
      // }
    }
    .platform {
      // width: 90%;
      margin-left: auto;
      margin-right: auto;
      img {
        margin-right: 22px;
      }
    }
    .campus {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (min-width: 570px) and(max-width:1440px) {
    .box {
      // max-width: 1100px !important;
      .area {
        // padding: 0 !important;
        // &_content {
        //   div:nth-child(1) span {
        //     padding: 0 20px;
        //   }
        //   p {
        //     margin-top: 30px;
        //     font-size: 18px;
        //     margin-bottom: 0;
        //   }
        // }
      }
      .platform {
        img {
          margin-right: 22px;
        }
      }
    }
  }
}
// mobile会将px转成vw
.view-mobile {
  @extend %area;
  @media screen and (max-width: 700px) {
    .area-product {
      margin-top: -15px !important;
      .content {
        padding-top: 0;
        padding-bottom: 30px;
        .title_en {
          font-size: 14px !important;
          margin-top: 10px;
        }
        h3 {
          line-height: 30px !important;
        }
        .box {
          width: 90%;
          margin-top: 0;
          .title {
            text-align: center;
            color: #333;
            h3 {
              // line-height: 60px;
              font-size: 40px;
              font-weight: bold;
              margin: 0;
            }
            .title_en {
              font-size: 18px;
            }
            .title_en::after {
              content: '';
              display: block;
              width: 66px;
              height: 3px;
              margin: 20px auto;
              background-color: $--color-primary;
            }
          }
          .area {
            padding: 0 0px 40px;
            .area_content {
              margin-top: 20px;
              p {
                margin: 0;
                font-size: 14px;
                line-height: 28px;
                color: #666;
                text-align: justify;
              }
              div:nth-child(1) span {
                padding: 0 20px;
              }
              .platform {
                display: block;
                padding: 20px;
                margin-top: 30px;
                margin-bottom: 0;
                img {
                  width: 100%;
                  margin: 0 !important;
                  // margin-bottom: 20px;
                }
              }
            }
          }
          .campus {
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
