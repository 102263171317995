<template>
  <div class="area-product">
    <!--  头部轮播  -->
    <div id="swiper">
      <!--      <swiper class="swiper" :options="swiperOption">-->
      <!--        <swiper-slide>-->
      <!--          <img-->
      <!--            src="@/assets/images/areaProduct/banner_products_area.png"-->
      <!--            alt="区域一体课程管理平台"-->
      <!--          />-->
      <!--        </swiper-slide>-->
      <!--        <swiper-slide v-for="(image, index) in swiperList" :key="index">-->
      <!--          <img :src="image.url" :alt="image.title" />-->
      <!--        </swiper-slide>-->

      <!--        <div class="swiper-pagination" slot="pagination"></div>-->
      <!--      </swiper>-->
      <swiper ref="swiper" class="swiper" :options="swiperOption">
        <swiper-slide v-for="(image, index) in navList" :key="index">
          <img :src="image.bannerBgUrl" :alt="image.title" />
          <div class="swiper_content">
            <div>
              <h1 v-html="image.title"></h1>
              <p>
                {{ image.bannerCon }}
              </p>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div
      ></swiper>
    </div>
    <div class="navigation">
      <ul>
        <li
          v-for="(nav, index) in navList"
          :key="index"
          @click="toggleNav(index, nav)"
        >
          <span
            :class="{
              'active-nav': activeNav === index,
            }"
            >{{ nav.title }}</span
          >
        </li>
      </ul>
    </div>
    <component :is="navList[activeNav].component"></component>
    <!-- <div class="content">
      <div class="box">
        <div class="area">
          <div class="title">
            <h3>区校一体课程管理平台</h3>
            <p class="title_en">District-school Course Management Platform</p>
          </div>
          <div class="area_content">
            <p>
              <span>区校一体化课程管理平台</span>
              为区域教育管理者提供基干大数据分析的科学决策，对区域内教师资源、教室资源、班额、师生比、课程、学生选科、走班思路与策略等方面提供有效的监控与分析;通过大数据基础平台的建设，深度挖掘常规业务数据的业务价值，提供更加科学有效的分析服务。
            </p>
          </div>
        </div>
        <div class="platform">
          <img src="@/assets/images/areaProduct/platform.png" alt="" />
          <p>
            为学校提供特色的
            <span
              >行政班排课、走班排课、智能分班、校本选课、新高考选课、教师调代课和各维度课表查询</span
            >的丰富功能。帮助学校一站式管理，支持灵活地定制符合学校自己实际情况的教务功能。
          </p>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import area1_banner from '@/assets/images/areaProduct/banner_products_area_q.jpg'
import area2_banner from '@/assets/images/areaProduct/banner_products_area_safety.png'
import area3_banner from '@/assets/images/areaProduct/banner_products_area_afterschool.jpg'
import area4_banner from '@/assets/images/areaProduct/banner_products_area_q_n.jpg'
import area5_banner from '@/assets/images/areaProduct/banner_products_area_s.jpg'

import platformBottomPc from '@/assets/images/areaProduct/platform_bottom_pc.png'
import platformBottomMobile from '@/assets/images/areaProduct/platform_bottom_mobile.png'
import AfterSchool from './area/AfterSchool'
import CourseManage from './area/CourseManage'
import Safety from './area/Safety'
import AcademicQuality from './area/AcademicQuality'
import SmartUnion from './area/SmartUnion'

export default {
  name: 'School',
  components: {
    swiper,
    swiperSlide,
    AfterSchool,
    CourseManage,
    Safety,
  },
  directives: {
    // swiper: directive,
  },
  data() {
    return {
      platformBottomPc,
      platformBottomMobile,
      // navList: [
      //   { label: '区域课程管理平台', url: '/products-plans/area' },
      //   { label: '区域校园安全风险防控平台', url: '/products-plans/safety' },
      //   {
      //     label: '区域课后服务云平台',
      //     url: '/products-plans/afterschool',
      //   },
      // ],
      navList: [
        {
          title: '区域课程管理平台',
          bannerBgUrl: area1_banner,
          bannerCon:
            '基于大数据平台，为区域管理者提供科学决策及分析服务，为学校一站式管理服务，支持灵活地定制个性化教务功能',
          componentName: 'CourseManage',
          component: CourseManage,
        },
        {
          title: '区域校园安全风险防控平台',
          bannerBgUrl: area2_banner,
          bannerCon:
            '为区域教育局建设多级安全管理风险防控平台，保障安全防控工作逐级、科学、规范开展',
          componentName: 'Safety',
          component: Safety,
        },
        {
          title: '区域课后服务云平台',
          bannerBgUrl: area3_banner,
          bannerCon:
            '面向教育局、学校、机构、教师、学生、家长的一站式课后服务平台，统筹规划课后服务的资金监管、课程设计、评估量化问题',
          componentName: 'AfterSchool',
          component: AfterSchool,
        },
        {
          title: '区域学业质量管理平台',
          bannerBgUrl: area4_banner,
          bannerCon:
            '聚焦区域教育测量与评价，为区域教育管理部门决策提供有效数据依据和信息化平台支撑',
          componentName: 'AcademicQuality',
          component: AcademicQuality,
        },
        {
          title: '区域智能物联管控平台',
          bannerBgUrl: area5_banner,
          bannerCon:
            '“区校双平台”并行，互联互通，多层次、高质量促进区域教育可持续发展',
          componentName: 'SmartUnion',
          component: SmartUnion,
        },
      ],
      activeNav: 0,
      swiperList: [
        {
          url: '图片路径',
          title: '图片描述',
        },
      ], // 轮播图片
      swiperOption: {
        // autoplay: {
        //   disableOnInteraction: false, // 用户操作后自动轮播时间重新开始计时
        //   delay: 5000, // 自动轮播切换时间
        // },
        autoplay: false,
        loop: true, // 开启循环
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          // dynamicBullets: true,
        },
      },
    }
  },
  watch: {
    $route(newVal) {
      const componentName = newVal.query.name
      console.log(componentName)
      this.navList.map((item, index) => {
        if (item.componentName === componentName) {
          this.activeNav = index
          this.$refs.swiper.swiper.slideTo(this.activeNav + 1, 500)
          this.$store.commit('setPcHeaderIsTransparent', true)
          console.log(this.activeNav)
        }
      })
    },
  },
  mounted() {
    // 设置header背景透明
    this.$store.commit('setPcHeaderIsTransparent', true)
  },
  methods: {
    toggleNav(index, item) {
      this.activeNav = index
      this.$refs.swiper.swiper.slideTo(this.activeNav + 1, 500)
      this.$router.push({
        path: '/products-plans/area',
        query: { name: item.componentName },
      })
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const componentName = to.query.name
      vm.navList.map((item, index) => {
        if (item.componentName === componentName) {
          vm.activeNav = index
          vm.$nextTick(() => {
            vm.$refs.swiper.swiper.slideTo(vm.activeNav + 1, 500)
          })
        }
      })
    })
  },
}
</script>

<style scoped lang="scss">
%area {
  #swiper {
    img {
      width: 100%;
    }
    position: relative;
    .swiper {
      @include px2vw('height', 750);
      img {
        width: 100%;
      }
      & > .swiper-pagination {
        width: auto !important;
        bottom: 30px;
        left: 50%;
        display: none;
        transform: translateX(-50%);
        font-size: 0;
        ::v-deep span {
          padding: 0 20px;
          margin: 0 4px;
          border-radius: 4px;
        }
        ::v-deep .swiper-pagination-bullet-active {
          background-color: $--color-white;
        }
      }
    }
    .swiper_content {
      position: absolute;
      left: 50%;
      top: 38%;
      width: 100%;
      max-width: 1374px;
      transform: translate(-50%, -50%);
      text-align: center;
      color: $--color-white;
      z-index: 2;
      text-shadow: 0px 2px 12px rgba(0, 0, 0, 0.5);
      h1 {
        @include px2vw('font-size', 56);
      }
      p {
        @include px2vw('font-size', 28);
      }
    }
  }
  .navigation {
    width: 90%;
    max-width: 1320px;
    margin: 50px auto;
    padding-top: 38px;
    padding-bottom: 8px;
    @include px2vw('padding-left', 90);
    @include px2vw('padding-right', 90);
    box-sizing: border-box;
    box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.08);
    & ul {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      li {
        min-width: 120px;
        box-sizing: border-box;
        display: flex;
        margin: 0 6px 30px;
        cursor: pointer;
        span {
          padding: 6px 10px;
        }
        span.active-nav {
          background-color: $--color-primary;
          color: $--color-white;
        }
      }
      li:hover {
        span {
          background-color: $--color-primary;
          color: $--color-white;
        }
      }
      li::after {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background-color: #999999;
        margin-left: 10px;
      }
      li:last-child()::after {
        content: '';
        display: none;
      }
    }
  }
  .content {
    background: #f7f7f8;
    padding-bottom: 40px;

    .box {
      max-width: 1320px;
      margin: 60px auto;

      .title {
        text-align: center;
        color: #333;
        h3 {
          line-height: 60px;
          font-size: 40px;
          font-weight: bold;
          margin: 0;
        }
        .title_en {
          font-size: 24px;
        }
        .title_en::after {
          content: '';
          display: block;
          width: 66px;
          height: 3px;
          margin: 20px auto;
          background-color: $--color-primary;
        }
      }
      .area {
        padding: 40px 60px;
        &_content {
          & > div:nth-child(1) {
            width: calc(100% - 20px);
            max-width: 800px;
            text-align: center;
            padding: 36px 0;
            border: 1px solid #979797;
            font-size: 28px;
            margin: 60px auto;
          }
          & > p {
            line-height: 32px;
            font-size: 18px;
            text-align: justify;
            color: #666;
            margin: 50px 0;
            span {
              color: $--color-primary;
            }
          }
        }
      }

      .platform {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: $--color-white;
        padding: 40px 20px;
        img {
          width: 30%;
        }
        & > p {
          max-width: 744px;
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          span {
            color: $--color-primary;
          }
        }
      }
    }
  }
  @media screen and (max-width: 700px) {
  }
  @media (min-width: 700px) and (max-width: 1200px) {
  }
}
.view-pc {
  @extend %area;
  @media screen and (max-width: 1200px) {
    .navigation {
      // max-width: 1100px;
      width: 90%;
    }
  }

  @media (min-width: 570px) and(max-width:1440px) {
    .navigation {
      // max-width: 1100px;
      // width: 90%;
      @include px2vw('padding-left', 50);
      @include px2vw('padding-right', 50);
    }
    .box {
      // max-width: 1100px !important;
      .area {
        padding: 0 !important;

        // &_content {
        //   div:nth-child(1) span {
        //     padding: 0 20px;
        //   }
        //   p {
        //     margin-top: 30px;
        //     font-size: 18px;
        //     margin-bottom: 0;
        //   }
        // }
      }
      .platform {
        img {
          margin-right: 25px;
        }
      }
    }
  }
}
// mobile会将px转成vw
.view-mobile {
  @extend %area;
  @media screen and (max-width: 700px) {
    .area-product {
      margin-top: 44px;
      #swiper {
        .swiper {
          height: 160px;
          & > .swiper-pagination {
            bottom: 10px;
            ::v-deep span {
              padding: 0 10px;
              margin: 0 4px;
              border-radius: 4px;
            }
            ::v-deep .swiper-pagination-bullet-active {
              background-color: $--color-white;
            }
          }
        }
        .swiper_content {
          h1 {
            font-size: 22px;
          }
          p {
            font-size: 12px;
            margin: 0 20px;
            line-height: 20px;
          }
        }
      }
      .navigation {
        margin: 0 auto 40px;
        padding-left: 10px;
        padding-right: 10px;
        & ul {
          width: 100%;
          display: flex;
          li {
            min-width: unset;
            max-width: unset;
            margin: 0 0 10px;
            padding: 0 6px;
            text-align: center;
            border-right: 1px #999999 solid;
            &::after {
              margin-left: 0;
              background-color: $--color-white;
            }
            &:last-child {
              border-right: 0;
            }
            span {
              padding: 6px;
              font-size: 14px;
            }
          }
        }
      }
      .content {
        .title_en {
          font-size: 14px !important;
          margin-top: 10px;
        }
        h3 {
          line-height: 30px !important;
        }
        .box {
          width: 90%;
          margin: 40px auto;
          .area {
            padding: 40px 0;
            &_content {
              div:nth-child(1) span {
                padding: 0 20px;
              }
              p {
                margin-top: 30px;
                font-size: 18px;
                margin-bottom: 0;
              }
            }
          }
          .platform {
            width: 90%;
            display: block;
            padding: 20px;
            img {
              width: 80%;
              display: block;
              margin: 20px auto;
            }
            p {
              font-size: 18px;
              line-height: 31px;
              color: #666;
              text-align: justify;
            }
          }
        }
      }
    }
  }
}
</style>
