<template>
  <div class="area-product">
    <!--  头部轮播  -->
    <div class="content">
      <div class="box">
        <div class="area">
          <div class="title">
            <h3>区域学业质量管理平台</h3>
            <!-- <p class="title_en">District-school Course Management Platform</p> -->
          </div>
          <div class="area_content">
            <p>
              专注于课后全场景大数据采集、分析和精准应用，覆盖全国31个省份的联考服务团队，自研的市级/局校/多校联考阅卷解决方案，全面覆盖考前、考中、考后环节，依托高速扫描仪、图像处理技术、大数据管理技术，实现基于互联网的学生试卷信息采集与支持教师多终端的试卷评阅。
            </p>
          </div>
        </div>
        <div class="platform">
          <img
            src="@/assets/images/areaProduct/AcademicQuality/pic1.png"
            alt=""
          />
          <p>
            通过对区域教学过程性数据监测，聚焦区域教育测量与评价，为区域教育管理部门决策提供有效数据依据和信息化平台支撑，实现教育管理的智能化，提升区域教育大数据管理水平。
          </p>
        </div>
      </div>
      <div class="campus_box">
        <div class="campus">
          <div class="title">
            <h3>系统用户层次图</h3>
            <!-- <p class="title_en">Intelligent Base Management System</p> -->
          </div>
          <div class="campus_content">
            <img src="@/assets/images/areaProduct/AcademicQuality/pic2.png" />
          </div>
        </div>
        <div class="campus pt0">
          <div class="title">
            <h3>联考组织流程</h3>
            <!-- <p class="title_en">Intelligent Base Management System</p> -->
          </div>
          <div class="campus_content">
            <img src="@/assets/images/areaProduct/AcademicQuality/pic3.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { swiper, swiperSlide, directive } from 'vue-awesome-swiper'
// import 'swiper/swiper-bundle.css'
import platformBottomPc from '@/assets/images/areaProduct/platform_bottom_pc.png'
import platformBottomMobile from '@/assets/images/areaProduct/platform_bottom_mobile.png'

export default {
  name: 'School',
  components: {
    // swiper,
    // swiperSlide,
  },
  directives: {
    // swiper: directive,
  },
  data() {
    return {
      platformBottomPc,
      platformBottomMobile,
      navList: [
        { label: '区域课程管理平台', url: '/products-plans/area' },
        { label: '区域校园安全风险防控平台', url: '/products-plans/safety' },
        {
          label: '区域课后服务云平台',
          url: '/products-plans/afterschool',
        },
      ],
      activeNav: 0,
      swiperList: [
        {
          url: '图片路径',
          title: '图片描述',
        },
      ], // 轮播图片
      swiperOption: {
        // swiper操作项
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          // dynamicBullets: true,
        },
      },
    }
  },
  mounted() {
    // 设置header背景透明
    this.$store.commit('setPcHeaderIsTransparent', true)
  },
  methods: {
    goPage(index, url) {
      this.activeNav = index
      this.$router.push(url)
    },
  },
}
</script>

<style scoped lang="scss">
%area {
  .content {
    background: #f7f7f8;
    // padding-bottom: 40px;
    // padding-top: 40px;
    .box {
      max-width: 1320px;
      width: 90%;
      margin: 0 auto 120px;

      .title {
        text-align: center;
        color: #333;
        h3 {
          line-height: 36px;
          font-size: 36px;
          font-weight: bold;
          margin: 0;
        }
        .title_en {
          font-size: 24px;
        }
        .title_en::after {
          content: '';
          display: block;
          width: 66px;
          height: 3px;
          margin: 20px auto;
          background-color: $--color-primary;
        }
      }
      .area {
        padding: 40px 0px 0;
        &_content {
          & > div:nth-child(1) {
            width: calc(100% - 20px);
            max-width: 800px;
            text-align: center;
            padding: 36px 0;
            border: 1px solid #979797;
            font-size: 28px;
            margin: 60px auto;
          }
          & > p {
            line-height: 32px;
            font-size: 18px;
            text-align: justify;
            color: #666;
            margin: 40px 0 50px;

            span {
              color: $--color-primary;
            }
          }
        }
      }

      .platform {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: $--color-white;
        padding: 40px 20px;
        img {
          width: 30%;
        }
        & > p {
          max-width: 744px;
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          span {
            color: $--color-primary;
          }
        }
      }
    }
    .campus_box {
      background: $--color-white;
      .campus {
        max-width: 1320px;
        margin: 0 auto;
        text-align: center;
        padding-top: 80px;
        .title {
          text-align: center;
          color: #333;
          margin-bottom: 40px;
          h3 {
            line-height: 36px;
            font-size: 36px;
            font-weight: bold;
            margin: 0;
          }
        }
        img {
          display: block;
          margin: 50px auto 0;
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
  }
  @media (min-width: 700px) and (max-width: 1200px) {
  }
}
.view-pc {
  @extend %area;
  .content {
    .box {
      margin-bottom: 80px;
    }
  }
  .pt0 {
    margin-bottom: 100px !important;
  }
  @media screen and (max-width: 1200px) {
    .area {
      // padding: 0 !important;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      // &_content {
      //   div:nth-child(1) span {
      //     padding: 0 20px;
      //   }
      //   p {
      //     margin-top: 30px;
      //     font-size: 18px;
      //     margin-bottom: 0;
      //   }
      // }
    }
    .content {
      padding-bottom: 0 !important;
    }
    .platform {
      width: calc(90% - 40px);
      margin-left: auto;
      margin-right: auto;
      img {
        margin-right: 25px;
      }
    }
  }
  @media (min-width: 570px) and(max-width:1440px) {
    .box {
      // max-width: 1100px !important;
      .area {
        // padding: 0 !important;
        // &_content {
        //   div:nth-child(1) span {
        //     padding: 0 20px;
        //   }
        //   p {
        //     margin-top: 30px;
        //     font-size: 18px;
        //     margin-bottom: 0;
        //   }
        // }
      }
      .content {
        padding-bottom: 0 !important;
      }
      .platform {
        img {
          margin-right: 25px;
        }
      }
    }
    .campus_box {
      background: $--color-white;
      .campus {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        // max-width: 1100px !important;
      }
    }
  }
}
// mobile会将px转成vw
.view-mobile {
  @extend %area;
  @media screen and (max-width: 700px) {
    .area-product {
      margin-top: -15px !important;
      .content {
        padding-top: 0px;
        .title_en {
          font-size: 14px !important;
          margin-top: 10px;
        }
        h3 {
          line-height: 30px !important;
        }
        .box {
          width: 90%;
          margin: 0 auto 30px;
          .area {
            padding: 0 0 30px;
            &_content {
              div:nth-child(1) span {
                padding: 0 20px;
              }
              p {
                margin-top: 20px;
                font-size: 14px;
                line-height: 28px;
                margin-bottom: 0;
              }
            }
          }
          .platform {
            width: 90%;
            display: block;
            padding: 20px;
            img {
              width: 80%;
              display: block;
              margin: 20px auto;
            }
            p {
              font-size: 14px;
              line-height: 28px;
              color: #666;
              text-align: justify;
            }
          }
        }
        .campus_box {
          .pt0 {
            padding-top: 0 !important;
          }
          .campus {
            padding: 30px 15px 0;
            .title {
              margin-bottom: 20px;
            }
            img {
              width: 100%;
              margin: 0 0 20px;
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
}
</style>
