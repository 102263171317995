<template>
  <div class="area-product">
    <!-- 导航内容  -->
    <div class="content">
      <div class="area">
        <div class="title">
          <h3>区域课后服务云平台</h3>
          <!-- <p class="title_en">
            District-school After-School Service Management Platform
          </p> -->
        </div>
        <div class="area_content">
          <p>
            <span>区域课后服务云平台</span>
            构建面向教育局、学校、机构、教师、学生、家长的一站式课后服务平台。解决课后服务的资金使用监管问题、解决课后服务优质均衡问题、解决课后服务课程单一问题、解决课后服务过程无法量化评估问题、为课后服务提供统筹规划支撑。
          </p>
          <div class="platform">
            <img
              src="@/assets/images/areaProduct/platform_afterschool_pc.png"
              alt=""
            />
            <p>
              从课程体系设计、资金安全管理、课时补贴发放、家校高效沟通、课程丰富程度、优质资源到班、课程质量评价等方面对政策落实情况进行实时、整体把控。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="content area-img">
      <div class="area newmain">
        <img src="@/assets/images/areaProduct/araecourse1.png" />
        <div class="xtjg">
          <h3>系统结构</h3>
          <img src="@/assets/images/areaProduct/araecourse2.png" />
        </div>
        <div class="cpjz">
          <h3>产品价值</h3>
          <img src="@/assets/images/areaProduct/araecourse3.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { swiper, swiperSlide, directive } from 'vue-awesome-swiper'
// import 'swiper/swiper-bundle.css'
import platformBottomPc from '@/assets/images/areaProduct/platform_bottom_pc.png'
import platformBottomMobile from '@/assets/images/areaProduct/platform_bottom_mobile.png'

export default {
  name: 'School',
  components: {
    // swiper,
    // swiperSlide,
  },
  directives: {
    // swiper: directive,
  },
  data() {
    return {
      platformBottomPc,
      platformBottomMobile,
      navList: [
        { label: '区域课程管理平台', url: '/products-plans/area' },
        { label: '区域校园安全风险防控平台', url: '/products-plans/safety' },
        {
          label: '区域课后服务云平台',
          url: '/products-plans/afterschool',
        },
      ],
      activeNav: 2,
      swiperList: [
        {
          url: '图片路径',
          title: '图片描述',
        },
      ], // 轮播图片
      swiperOption: {
        // swiper操作项
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          // dynamicBullets: true,
        },
      },
    }
  },
  mounted() {
    // 设置header背景透明
    this.$store.commit('setPcHeaderIsTransparent', true)
  },
  methods: {
    goPage(index, url) {
      this.activeNav = index
      this.$router.push(url)
    },
  },
}
</script>

<style scoped lang="scss">
%area {
  .content {
    background: #f7f7f8;
    padding-bottom: 40px;
    // padding-top: 40px;
    // 通用头部样式
    .title {
      text-align: center;
      color: #333;
      h3 {
        line-height: 36px;
        font-size: 36px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 24px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    // 新高考
    .area {
      width: 90%;
      max-width: 1320px;
      margin: 0 auto;
      padding: 40px 0;
      &-img {
        h3 {
          line-height: 36px;
          font-size: 36px;
        }
      }
      &_content {
        & > div:nth-child(1) {
          width: calc(100% - 20px);
          max-width: 800px;
          text-align: center;
          padding: 36px 0;
          border: 1px solid #979797;
          font-size: 28px;
          margin: 60px auto;
        }
        & > p {
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          margin: 40px 0 50px;
          span {
            color: $--color-primary;
          }
        }
        .platform {
          display: flex;
          justify-content: space-around;
          align-items: center;
          background: $--color-white;
          padding: 60px 20px;
          margin-bottom: 30px;
          & > p {
            max-width: 744px;
            line-height: 32px;
            font-size: 18px;
            text-align: justify;
            color: #666;
            text-align: justify;
            span {
              color: $--color-primary;
            }
          }
          img {
            width: 30%;
          }
        }
      }
    }
    // 智慧校园
    .campus {
      margin: 0px auto;
      text-align: center;
      img {
        width: 90%;
      }
    }
  }
  .area-img {
    text-align: center;
    background: $--color-white;
    margin-top: 50px;
    img {
      width: 100%;
    }
    p {
      // line-height: 60px;
      font-size: 40px;
      font-weight: bold;
      margin-top: 60px;
    }
  }
}
.view-pc {
  @extend %area;
  .content {
    &:last-child {
      padding-bottom: 100px;
    }
  }
  .area-img h3 {
    line-height: 36px;
    font-size: 36px;
    font-weight: bold;
    margin: 50px auto;
  }
  .area {
    padding-bottom: 0 !important;
  }
  @media screen and (max-width: 1200px) {
    .area {
      // max-width: 1100px !important;
      // padding: 0 !important;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      // &_content {
      //   div:nth-child(1) span {
      //     padding: 0 20px;
      //   }
      //   p {
      //     margin-top: 30px;
      //     font-size: 18px;
      //     margin-bottom: 0;
      //   }
      // }
    }
    .platform {
      img {
        margin-right: 25px;
      }
    }
  }
  @media (min-width: 570px) and(max-width:1440px) {
    .area {
      // max-width: 1100px !important;
      // padding: 0 !important;
      // &_content {
      //   div:nth-child(1) span {
      //     padding: 0 20px;
      //   }
      //   p {
      //     margin-top: 30px;
      //     font-size: 18px;
      //     margin-bottom: 0;
      //   }
      // }
    }
    .platform {
      img {
        margin-right: 25px;
      }
    }
    // .box {
    //   max-width: 1100px !important;

    // }
  }
}
// mobile会将px转成vw
.view-mobile {
  @extend %area;
  .area-product {
    margin-top: -15px !important;
    .content {
      padding: 0;
      box-sizing: border-box;
      .title_en {
        font-size: 14px !important;
        margin-top: 10px;
      }
      h3 {
        line-height: 30px !important;
      }
      .title {
        text-align: center;
        color: #333;
        h3 {
          // line-height: 60px;
          font-size: 40px;
          font-weight: bold;
          margin: 0;
        }
        .title_en {
          font-size: 18px;
        }
        .title_en::after {
          content: '';
          display: block;
          width: 66px;
          height: 3px;
          margin: 20px auto;
          background-color: $--color-primary;
        }
      }
      .area {
        width: 90%;
        padding-top: 0;
        padding-bottom: 10px;
        .area_content {
          margin-top: 20px;
          div:nth-child(1) span {
            padding: 0 20px;
          }
          p {
            margin: 0;
            font-size: 14px;
            line-height: 28px;
            color: #666;
            text-align: justify;
          }
          .platform {
            display: block;
            padding: 20px;
            margin-top: 30px;
            img {
              width: 100%;
              margin-bottom: 20px;
            }
            p {
              font-size: 14px;
              line-height: 28px;
            }
          }
        }
      }
      .cpjz {
        img {
          margin-bottom: 20px;
        }
      }
      .campus {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
